import {
  AuthError,
  fetchAuthSession,
  signIn,
  signOut,
  type AuthUser,
  type SignInInput,
  type SignInOutput,
  getCurrentUser,
} from 'aws-amplify/auth';

export const handleSignIn = async ({ username, password }: SignInInput): Promise<SignInOutput> => {
  try {
    const user = await getCurrentUser();
    console.log(user);
    if (user?.userId && user?.username) {
      return {
        isSignedIn: true,
        nextStep: {
          signInStep: 'DONE',
        },
      };
    }
  } catch (error) {
    console.log('error signing in', error);
  }

  try {
    const signInResult = await signIn({ username, password });
    return signInResult;
  } catch (error) {
    console.log('error signing in', error);
  }
};

export const currentAuthenticatedUser = async (): Promise<AuthUser> => {
  try {
    const currentUser = await getCurrentUser();
    return currentUser;
  } catch (error: unknown) {
    if (error instanceof AuthError) {
      console.error(JSON.stringify(error));
      console.error(error);
    } else {
      console.error(error);
    }
  }
};

export const handleSignOut = async (): Promise<void> => {
  try {
    await signOut();
  } catch (error) {
    console.log('error signing out: ', error);
  }
};

export const currentSession = async () => {
  try {
    const authSession = await fetchAuthSession();
    return authSession;
  } catch (err) {
    console.log(err);
  }
};
