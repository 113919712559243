import type { TranslocoOptions } from '@jsverse/transloco';
import { environment } from 'environments/environment';
import { TranslocoHttpLoader } from './core/transloco/transloco.http-loader';

export const translocoConfig: TranslocoOptions = {
    config: {
        availableLangs: [
            {
                id: 'en',
                label: 'English',
            },
            {
                id: 'de',
                label: 'Deutsch',
            },
            {
                id: 'es',
                label: 'Español',
            },
            {
                id: 'fr',
                label: 'Française',
            },
            {
                id: 'zh',
                label: '普通话',
            },
        ],
        defaultLang: navigator.language.split('-')[0],
        fallbackLang: 'en',
        reRenderOnLangChange: true,
        prodMode: environment.production,
    },
    loader: TranslocoHttpLoader
};
