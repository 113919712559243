/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../app/API.service";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const organizations = /* GraphQL */ `query Organizations {
  organizations {
    id
    name
    version
    marketPlayerType
    logo
    focus
    headQuarters {
      addressLines
      zipCode
      country
      __typename
    }
    locations {
      addressLines
      zipCode
      country
      __typename
    }
    contactInformation
    businessRegistration
    taxNumber
    twitterHandle
    twitterHandleActive
    rssFeed
    rssFeedActive
    website
    linkedIn
    linkedInActive
    facebook
    youTube
    youTubeActive
    instagram
    tiktok
    mailbox
    podcast
    email
    __typename
  }
}
` as GeneratedQuery<
  APITypes.OrganizationsQueryVariables,
  APITypes.OrganizationsQuery
>;
export const organization = /* GraphQL */ `query Organization($input: GetOrganizationInput) {
  organization(input: $input) {
    id
    name
    version
    marketPlayerType
    logo
    focus
    headQuarters {
      addressLines
      zipCode
      country
      __typename
    }
    locations {
      addressLines
      zipCode
      country
      __typename
    }
    contactInformation
    businessRegistration
    taxNumber
    twitterHandle
    twitterHandleActive
    rssFeed
    rssFeedActive
    website
    linkedIn
    linkedInActive
    facebook
    youTube
    youTubeActive
    instagram
    tiktok
    mailbox
    podcast
    email
    __typename
  }
}
` as GeneratedQuery<
  APITypes.OrganizationQueryVariables,
  APITypes.OrganizationQuery
>;
export const updates = /* GraphQL */ `query Updates($input: UpdatesPaginationInput) {
  updates(input: $input) {
    updates {
      ... on Tweet {
        id
        sourceId
        text
        conversation_id
        author_id
        created_at
        createdAt
        lang
        unread
        starred
        username
        folder
        type
        organizationId
        topicIds
      }
      ... on RssFeedItem {
        id
        sourceId
        text
        title
        description
        content
        contentSnippet
        created_at
        createdAt
        pubDate
        rssFeedTitle
        username
        folder
        type
        organizationId
        topicIds
      }
      ... on Email {
        id
        sourceId
        from
        subject
        text
        messageId
        date
        eml
        emailSrc
        folder
        type
        createdAt
        organizationId
        topicIds
      }
      ... on YoutubeUpdateItem {
        id
        sourceId
        text
        title
        kind
        description
        createdAt
        publishedAt
        channelTitle
        channelId
        username
        folder
        type
        organizationId
        topicIds
      }
      ... on LinkedInUpdateItem {
        id
        sourceId
        folder
        type
        createdAt
        publishedAt
        lastModifiedAt
        organizationId
        authorUrn
        vanityName
        text
        commentary
        topicIds
      }
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.UpdatesQueryVariables, APITypes.UpdatesQuery>;
export const matrix = /* GraphQL */ `query Matrix($input: GetMatrixInput!) {
  matrix(input: $input) {
    data {
      id
      name
      description
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<APITypes.MatrixQueryVariables, APITypes.MatrixQuery>;
