import { Injectable } from '@angular/core';
import { generateClient } from '@aws-amplify/api';
import type { CreateOrganizationMutationVariables, MatrixCellValueUpdateMutationVariables, MatrixQueryVariables, MoveUpdateMutationVariables, SetUpdateTopicsMutationVariables, UpdateOrganizationMutationVariables, UpdatesPaginationInput, UpdatesQueryVariables } from 'app/API.service';
import { createOrganization, matrixCellValueUpdate, moveUpdate, setUpdateTopics, updateOrganization } from 'graphql/mutations';
import { matrix, organizations, updates } from 'graphql/queries';

@Injectable({
  providedIn: 'root'
})
export class PicasiApiService {

  private client = generateClient();

  constructor() { }

  async Organizations() {
    const result = this.client.graphql({
      query: organizations
    });
    return result;
  }

  async CreateOrganization(input: CreateOrganizationMutationVariables) {
    const result = this.client.graphql({
      query: createOrganization,
      variables: input
    });
    return result;
  }

  async UpdateOrganization(input: UpdateOrganizationMutationVariables) {
    const result = this.client.graphql({
      query: updateOrganization,
      variables: input
    });
    return result;
  }

  async Updates(input: UpdatesQueryVariables) {
    const result = this.client.graphql({
      query: updates,
      variables: input
    });
    return result;
  }

  async SetUpdateTopics(input: SetUpdateTopicsMutationVariables) {
    const result = this.client.graphql({
      query: setUpdateTopics,
      variables: input
    });
    return result;
  }

  async MoveUpdate(input: MoveUpdateMutationVariables) {
    const result = this.client.graphql({
      query: moveUpdate,
      variables: input
    });
    return result;
  }

  async Matrix(input: MatrixQueryVariables) {
    const result = this.client.graphql({
      query: matrix,
      variables: input
    });
    return result;
  }

  async MatrixCellValueUpdate(input: MatrixCellValueUpdateMutationVariables) {
    const result = this.client.graphql({
      query: matrixCellValueUpdate,
      variables: input
    });
    return result;
  }

}