/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../app/API.service";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const createOrganization = /* GraphQL */ `mutation CreateOrganization($input: CreateOrganizationInput) {
  createOrganization(input: $input) {
    id
    name
    version
    marketPlayerType
    logo
    focus
    headQuarters {
      addressLines
      zipCode
      country
      __typename
    }
    locations {
      addressLines
      zipCode
      country
      __typename
    }
    contactInformation
    businessRegistration
    taxNumber
    twitterHandle
    twitterHandleActive
    rssFeed
    rssFeedActive
    website
    linkedIn
    linkedInActive
    facebook
    youTube
    youTubeActive
    instagram
    tiktok
    mailbox
    podcast
    email
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateOrganizationMutationVariables,
  APITypes.CreateOrganizationMutation
>;
export const updateOrganization = /* GraphQL */ `mutation UpdateOrganization($input: UpdateOrganizationInput) {
  updateOrganization(input: $input) {
    id
    name
    version
    marketPlayerType
    logo
    focus
    headQuarters {
      addressLines
      zipCode
      country
      __typename
    }
    locations {
      addressLines
      zipCode
      country
      __typename
    }
    contactInformation
    businessRegistration
    taxNumber
    twitterHandle
    twitterHandleActive
    rssFeed
    rssFeedActive
    website
    linkedIn
    linkedInActive
    facebook
    youTube
    youTubeActive
    instagram
    tiktok
    mailbox
    podcast
    email
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateOrganizationMutationVariables,
  APITypes.UpdateOrganizationMutation
>;
export const deleteOrganization = /* GraphQL */ `mutation DeleteOrganization($input: DeleteOrganizationInput!) {
  deleteOrganization(input: $input) {
    id
    name
    version
    marketPlayerType
    logo
    focus
    headQuarters {
      addressLines
      zipCode
      country
      __typename
    }
    locations {
      addressLines
      zipCode
      country
      __typename
    }
    contactInformation
    businessRegistration
    taxNumber
    twitterHandle
    twitterHandleActive
    rssFeed
    rssFeedActive
    website
    linkedIn
    linkedInActive
    facebook
    youTube
    youTubeActive
    instagram
    tiktok
    mailbox
    podcast
    email
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteOrganizationMutationVariables,
  APITypes.DeleteOrganizationMutation
>;
export const setUpdateRead = /* GraphQL */ `mutation SetUpdateRead($input: setUpdateReadInput!) {
  setUpdateRead(input: $input)
}
` as GeneratedMutation<
  APITypes.SetUpdateReadMutationVariables,
  APITypes.SetUpdateReadMutation
>;
export const moveUpdate = /* GraphQL */ `mutation MoveUpdate($input: moveUpdateInput) {
  moveUpdate(input: $input) {
    id
    __typename
  }
}
` as GeneratedMutation<
  APITypes.MoveUpdateMutationVariables,
  APITypes.MoveUpdateMutation
>;
export const setUpdateTopics = /* GraphQL */ `mutation SetUpdateTopics($input: SetUpdateTopicsInput) {
  setUpdateTopics(input: $input) {
    id
    topicIds
    __typename
  }
}
` as GeneratedMutation<
  APITypes.SetUpdateTopicsMutationVariables,
  APITypes.SetUpdateTopicsMutation
>;
export const subscribeNotification = /* GraphQL */ `mutation SubscribeNotification($input: WebNotificationSubscriptionInput) {
  subscribeNotification(input: $input)
}
` as GeneratedMutation<
  APITypes.SubscribeNotificationMutationVariables,
  APITypes.SubscribeNotificationMutation
>;
export const notify = /* GraphQL */ `mutation Notify($input: NotificationInput) {
  notify(input: $input) {
    id
    icon
    image
    title
    description
    time
    link
    useRouter
    topic
    __typename
  }
}
` as GeneratedMutation<
  APITypes.NotifyMutationVariables,
  APITypes.NotifyMutation
>;
export const matrixCellValueUpdate = /* GraphQL */ `mutation MatrixCellValueUpdate($input: MatrixCellDataUpdateInput) {
  matrixCellValueUpdate(input: $input) {
    value {
      id
      dataType
      description
      value
      validFrom
      validTo
      isEditable
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.MatrixCellValueUpdateMutationVariables,
  APITypes.MatrixCellValueUpdateMutation
>;
export const createMatrixCell = /* GraphQL */ `mutation CreateMatrixCell($input: CreateMatrixCellInput) {
  createMatrixCell(input: $input) {
    value {
      id
      dataType
      description
      value
      validFrom
      validTo
      isEditable
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateMatrixCellMutationVariables,
  APITypes.CreateMatrixCellMutation
>;
