import { Component, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { AppVersionService } from './core/version/app-version.service';

@Component({
    selector   : 'app-root',
    templateUrl: './app.component.html',
    styleUrls  : ['./app.component.scss'],
    standalone : true,
    imports    : [RouterOutlet],
})
export class AppComponent {

    constructor() { 
        const version = inject(AppVersionService);
        console.log('Application Version', version.getVersion());
    }
}
