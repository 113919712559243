import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { UserService } from 'app/core/user/user.service';
import { catchError, from, map, Observable, of, switchMap, tap, throwError } from 'rxjs';
import {  currentAuthenticatedUser, currentSession, handleSignIn, handleSignOut } from './amplify/cognito.service';
import { getCurrentUser } from 'aws-amplify/auth';

@Injectable({providedIn: 'root'})
export class AuthService {
    
    private _authenticated: boolean = false;
    private _httpClient = inject(HttpClient);
    private _userService = inject(UserService);


    set accessToken(token: string) {
        localStorage.setItem('accessToken', token);
    }

    get accessToken(): string {
        return localStorage.getItem('accessToken') ?? '';
    }

    checkExistingLogin(): Observable<boolean> {
        console.log('check existing login');
        const result = from(currentSession()).pipe(
            switchMap((session) => {
                console.log('Session', session);
                this.accessToken = session.tokens?.accessToken.toString();
                return from(currentAuthenticatedUser())
            }),
            switchMap(user => {
                console.log('Check user', user);
                this._userService.user = { id: user.userId, email: user.username, name: user.username};
                this._authenticated = true;
                return of( this._authenticated );
            }),
            catchError(err => {
                this._authenticated = false;
                this.accessToken = undefined;
                return of(false)
    })
        );
        console.log('Check existing result', result);
        return result;
    }

    /**
     * Forgot password
     *
     * @param email
     */
    forgotPassword(email: string): Observable<any> {
        return this._httpClient.post('api/auth/forgot-password', email);
    }

    /**
     * Reset password
     *
     * @param password
     */
    resetPassword(password: string): Observable<any>
    {
        return this._httpClient.post('api/auth/reset-password', password);
    }

    /**
     * Sign in
     *
     * @param credentials
     */
    signIn(credentials: { email: string; password: string }): Observable<boolean> {
        console.log('SignIn', credentials.email);
        if ( this._authenticated ) {
            return throwError(() => new Error('User is already logged in.'));
        }

        const { email: username, password } = credentials;
        
        return from(handleSignIn({username, password})).pipe(
            switchMap((signInResult) => {
                console.log(signInResult.nextStep);
                this._authenticated = signInResult.isSignedIn;
                return from(currentSession())
            }),
            switchMap((session) => {
                this.accessToken = session.tokens.accessToken.toString();
                return from(getCurrentUser())
            }),
            switchMap(user => {
                this._userService.user = { id: user.userId, email: user.username, name: user.username};
                return of( this._authenticated );
            })
        );
    }

    signInUsingToken(): Observable<any> {
        // Sign in using the token
        return this._httpClient.post('api/auth/sign-in-with-token', {
            accessToken: this.accessToken,
        }).pipe(
            catchError(() =>

                // Return false
                of(false),
            ),
            switchMap((response: any) =>
            {
                // Replace the access token with the new one if it's available on
                // the response object.
                //
                // This is an added optional step for better security. Once you sign
                // in using the token, you should generate a new one on the server
                // side and attach it to the response object. Then the following
                // piece of code can replace the token with the refreshed one.
                if ( response.accessToken )
                {
                    this.accessToken = response.accessToken;
                }

                // Set the authenticated flag to true
                this._authenticated = true;

                // Store the user on the user service
                this._userService.user = response.user;

                // Return true
                return of(true);
            }),
        );
    }

    /**
     * Sign out
     */
    signOut(): Observable<any> {
        return from(handleSignOut()).pipe(
            tap(_ =>  {
                localStorage.removeItem('accessToken');
                this._authenticated = false;
            }),
            map(_ => {
                return false
            })
        );
    }

    /**
     * Sign up
     *
     * @param user
     */
    signUp(user: { name: string; email: string; password: string; company: string }): Observable<any>
    {
        return this._httpClient.post('api/auth/sign-up', user);
    }

    /**
     * Unlock session
     *
     * @param credentials
     */
    unlockSession(credentials: { email: string; password: string }): Observable<any>
    {
        return this._httpClient.post('api/auth/unlock-session', credentials);
    }

    /**
     * Check the authentication status
     */
    check(): Observable<boolean> {
        console.log('Check loging');
        if (this._authenticated ) {
            return of(true);
        }
        return this.checkExistingLogin();
    }
}
