import { Injectable } from '@angular/core';
import { version, commitHash, buildDate } from 'environments/version';

@Injectable({
  providedIn: 'root'
})
export class AppVersionService {

  constructor() { }

  getVersion(): string {
    return `Version ${version}, commit ${commitHash}, built at ${buildDate}`;
  }
  
}
