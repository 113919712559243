import { Store, createState } from '@ngneat/elf';
import { selectAllEntities, setEntities, addEntities, updateEntities, deleteEntities, withUIEntities, withActiveId, selectActiveEntity, setActiveId, withEntities, selectActiveId } from '@ngneat/elf-entities';
import { Injectable, inject } from '@angular/core';
import { distinctUntilChanged, from, map, Observable, shareReplay, tap, switchMap } from 'rxjs';
import { log } from 'app/common/rxjs/log';
import { UpdateOrganizationInput, UpdateOrganizationMutation, type CreateOrganizationMutationVariables, type OrganizationQuery, type OrganizationsQuery } from 'app/API.service';
import type { Organization } from '../organizations.type';
import { PicasiApiService } from 'app/api/picasi-api.service';

export interface OrganizationUI {
  id: number;
}

const { state, config } = createState(
  withEntities<Organization>(),
  withActiveId()
);

const store = new Store({ name: 'organization', state, config });

@Injectable({ providedIn: 'root' })
export class OrganizationRepository {

  activeOrganization$ = store.pipe(selectActiveEntity());
  organizations$ = store.pipe(selectAllEntities(), shareReplay());
  activeId$ = store.pipe(selectActiveId(), distinctUntilChanged(), log('OrganizationRepository active id'), shareReplay());
  private api: PicasiApiService;

  constructor() {
    this.api = inject(PicasiApiService);
    this.loadOrganizations();
  }


  loadOrganizations(): void {
    from(this.api.Organizations()).pipe(
      log(),
      map(organizations => organizations.data.organizations),
      map(orgs => orgs.map(this.mapToOrganization)),
      log(),
      tap(organizations => this.setOrganization(organizations))
    ).subscribe();
  }


  mapToOrganization = (oq: OrganizationsQuery['organizations'][number]): Organization => {
    const { __typename, headQuarters, locations, ...rest} = oq;
    for (const prop in rest) {
      const value = rest[prop];
      if (value === undefined || value === null) {
      } else
      if (Array.isArray(value)) {
        rest[prop] = (value as Organization & { __typename: string}[]).map(({__typename, ...vr}) => vr);
      } else if (typeof value === 'object' && value.__typename !== undefined) {
        const {__typename, ...obj} = value;
        rest[prop] = obj;
      }
    }
    return rest as unknown as Organization;

  }

  setOrganization(organization: Organization[]): void {
    organization.sort((a, b) => a.name.toLowerCase() < b.name.toLowerCase() ? - 1 : a.name.toLowerCase() > b.name.toLowerCase() ? 1 : 0);
    store.update(setEntities(organization));
  }

  addOrganization(organization: Organization): void {
    store.update(addEntities(organization));
  }

  updateOrganization(id: Organization['id'], organization: Partial<Organization>): void {
    store.update(updateEntities(id, organization));
  }

  persistOrganization(id: Organization['id'], organization: UpdateOrganizationInput): Observable<UpdateOrganizationMutation['updateOrganization']> {
    if (id === 'new') {
      const { id, ...newOrga } = organization;
      return from(this.api.CreateOrganization(newOrga as CreateOrganizationMutationVariables)).pipe(
        tap(_ => this.loadOrganizations()),
        map(result => result.data.createOrganization)
      );
    } else {
      delete (organization as any).mailbox;
      return from(this.api.UpdateOrganization(organization as any)).pipe(map(result => result.data.updateOrganization));
    }
    
  }

  deleteOrganization(id: Organization['id']): void {
    store.update(deleteEntities(id));
  }

  setActiveId(id: Organization['id']): void {
    store.update(setActiveId(id));
  }



}
