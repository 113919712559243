import { bootstrapApplication } from '@angular/platform-browser';
import { configureAmplify } from 'amplify.config';
import { AppComponent } from 'app/app.component';
import { appConfig } from 'app/app.config';
import { environment } from 'environments/environment';

const amplifyConfig = configureAmplify(environment);
console.log('Amplify Configuration', amplifyConfig);

bootstrapApplication(AppComponent, appConfig)
    .catch(err => console.error(err));
