import { AngularEnvironment } from 'global';

export const environment: AngularEnvironment = {
    production: true,
    Auth: {
        Cognito: {
            userPoolClientId: '1el4lkpf3gnqmp0qoi187rfsdh',
            userPoolId: 'eu-central-1_iHA74j5bn'
        }
    },
    GraphQL: {
      endpoint: 'https://graphql-api.dev.picasi.app/graphql',
      region: 'eu-central-1',
      defaultAuthMode: 'userPool'
    },
    email: {
       domain: '@news.picasi.email'
    }
};
